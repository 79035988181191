@import '../../../styles/main.scss';


.container {
    display: flex;
    align-self: center;
    flex-direction: column;
    min-height: 50vh;
    width: 100%;
    padding-bottom: 2.5rem;

    .content_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3rem;
        padding-top: 1rem;
        opacity: 0;


        .section {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .text {
            font-size: $text-xxs;
            margin-bottom: 1.5rem;
            line-height: 2;

        }

        .button_wrapper {
            position: relative;
            width: fit-content;
            padding-top: 1rem;

            .arrow {
                position: absolute;
                height: 4.5rem;
                fill: $primary;
                right: -40%;
                top: -45%;
                transform: rotate(60deg)
            }
        }

        img {
            height: 22.5vw;
            border-radius: 100%;
            align-self: center;
        }
    }

    @include media-breakpoint-down(md) {
        min-height: unset;

        .content_container {
            flex-direction: column;

            .text {
                font-size: $text-xs;
                text-align: center;
                max-width: 750px;
                margin: auto;
            }

            .section {
                align-items: center;
            }

            .button_wrapper {
                position: relative;
                width: fit-content;
                padding-top: 3rem;
                margin-left: -6rem;

                .arrow {
                    height: 5.5rem;
                    right: -40%;
                    top: -2.5%;
                    transform: rotate(60deg)
                }
            }

            img {
                height: 22.5vw;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .content_container {
            img {
                height: 42.5vw;
            }
        }
    }
}