@import '../../../styles/main.scss';

.button {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    border-width: .125rem;
    border-color: $dark;
    border-style: solid;
    padding: .5rem 1.1rem;
    background-color: $primary;
    transition: all 0.2s ease-out;
    border-radius: .35rem;
    gap: .5rem;
    color: transparent;
    min-width: 160px;

    .icon {
        fill: $dark;
        height: 1rem;
        width: 1rem;
    }

    .text_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1rem;
        border-bottom: none !important;
    }

    p {
        font-size: $text-xxs;
        color: $dark;
        font-weight: bold;
        line-height: 0;
        white-space: nowrap;
        text-align: center;
        border: none !important;
    }

    @include media-breakpoint-down(sm) {
        border-width: .145rem;
        padding: .45rem 1.1rem;

        .text_container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2rem;
        }

        .icon {
            fill: $dark;
            height: 24px;
            width: 24px;
        }

        p {
            font-size: $text-xs;
        }

    }
}

.button:hover {
    background-color: $dark;

    .icon {
        fill: white;
    }

    p {
        color: white;
    }
}

.icon_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark;
    border-radius: 100%;
    height: 1.7rem;
    aspect-ratio: 1;
    transition: all 0.2s ease-out;
    z-index: $z-bottom;
    font-size: $text-xs;


    .icon {
        fill: white;
        width: 55%;
    }

    @include media-breakpoint-down(sm) {
        height: 2.75rem;
    }
}

.icon_button:hover {
    background-color: $background;

    .icon {
        fill: white;
    }

    p {
        color: white;
    }
}

.link {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: flex-end;
    transition: all 0.2s ease-out;
    border-radius: .35rem;
    gap: .5rem;
    align-items: center;
    padding: unset;


    .icon {
        fill: $dark;
        height: 1rem;
        width: 1rem;
    }

    p {
        font-size: $text-xxs;
        color: $dark;
        white-space: nowrap;
    }

    @include media-breakpoint-down(sm) {
        .icon {
            height: 24px;
            width: 24px;
        }

        p {
            font-size: $text-xs;
        }
    }
}

.bold {
    p {
        font-weight: bold;
    }
}

// Link Themes
.link_green {
    border-color: $primary;


    .icon {
        fill: $primary;
    }

    p {
        color: $primary;
    }
}

.link_filled_green {
    background-color: transparent;

    .icon {
        fill: $primary;
    }

    p {
        color: $primary;
    }
}

.link_white {
    border-color: $white;
    padding: unset;


    .icon {
        fill: $white;
    }

    p {
        color: $white;
    }
}

// Button Themes
.button_green {
    border-color: $primary;
    background-color: $white;

    .icon {
        fill: $primary;
    }

    p {
        color: $primary;
    }
}

.button_green:hover {
    background-color: $primary;

    .icon {
        fill: $white;
    }

    p {
        color: $white;
    }
}

.button_filled_green {
    border-color: $primary;
    background-color: $primary;

    .icon {
        fill: white;
    }

    p {
        color: $white;
    }
}

.button_filled_green:hover {
    background-color: white;
    // background-color: transparent;

    .icon {
        fill: $primary;
    }

    p {
        color: $primary;
    }
}

.button_filled_white {
    border-color: $white;
    background-color: $white;

    .icon {
        fill: $primary;
    }

    p {
        color: $dark;
    }
}

.button_filled_white:hover {
    background-color: $primary;

    .icon {
        fill: $white;
    }

    p {
        color: $white;
    }
}

.button_white {
    border-color: $white;
    background-color: transparent;

    .icon {
        fill: white;
    }

    p {
        color: white;
    }
}

// .button_white:hover {
//     background-color: black;

//     .icon {
//         fill: $white;
//     }

//     p {
//         color: $white;
//     }
// }