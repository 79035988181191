@import '../../../styles/main.scss';


.container {
    display: flex;
    align-self: center;
    flex-direction: column;
    min-height: 50vh;
    width: 100%;
    padding-bottom: 2.5rem;

    .content_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3rem;
        padding-top: 1rem;


        .section {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .text {
            font-size: $text-xxs;
            margin-bottom: 1.5rem;
            line-height: 2;
        }

        img {
            height: 22.5vw;
            border-radius: 100%;
            align-self: center;
        }
    }

    @include media-breakpoint-down(xs) {
        .content_container {
            flex-direction: column;

            .text {
                font-size: $text-xs;
                margin-bottom: 1.5rem;
                line-height: 2;
            }

            .section {
                align-items: center;
            }

            img {
                height: 42.5vw;
            }
        }
    }
}