@import '../../../styles/main.scss';


.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    min-height: 5rem;
    font-size: 1.25rem;

    .labelWrapper {
        display: flex;
        align-items: center;
        opacity: 0;

        .border {
            height: .08em;
            aspect-ratio: 25;
            background-color: $primary;
        }

        p {
            color: $primary;
            font-size: $text-xxs;
            font-weight: bold;
            margin-bottom: unset;
            padding-left: .5rem;
            padding-right: .5rem;
        }
    }


    .mouseScroll {
        position: absolute;
        opacity: 0;
        display: flex;
        align-items: center;
        gap: .75rem;
        justify-content: center;

        .mouse {
            width: 1em;
            border-radius: 1em;
            border: .1em solid $dark;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            aspect-ratio: .5;
        }

        .mouseIn {
            width: .6em;
            border-radius: 1em;
            background-color: $primary;
            aspect-ratio: 1;
            display: block;
            margin: .15em auto;
            -webkit-animation: animated-mouse 2s ease-in-out infinite alternate;
        }

        p {
            color: $dark;
            font-size: $text-xxs;
            font-weight: bold;
            margin-bottom: 0;
        }

        @keyframes animated-mouse {
            0% {
                -webkit-transform: translateY(0);
                -ms-transform: translateY(0);
                transform: translateY(0);
            }

            100% {
                -webkit-transform: translateY(.9em);
                -ms-transform: translateY(.9em);
                transform: translateY(.9em);
            }
        }


        @-webkit-keyframes animated-mouse {
            0% {
                -webkit-transform: translateY(0);
                -ms-transform: translateY(0);
                transform: translateY(0);
            }

            100% {
                -webkit-transform: translateY(.9em);
                -ms-transform: translateY(.9em);
                transform: translateY(.9em);
            }
        }

    }

    @include media-breakpoint-down(md) {
        .labelWrapper {
            p {
                font-size: $text-xs;
            }
        }

        .mouseScroll {
            font-size: 1.5rem;
            width: 12rem;

            p {
                font-size: $text-xs;
            }
        }

    }
}