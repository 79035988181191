html,
body {
  background-color: $background;
  color: $dark;
  font-size: clamp(14px, 1.6vw, 28px);
  min-width: 320px;
  line-height: 1.4;
  font-weight: 400;
  letter-spacing: .05em;

  ::selection {
    background-color: $primary-200;
  }

}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.1;
}

h1,
.h1 {
  font-size: $text-h1;
}

h2,
.h2 {
  font-size: $text-h2;
}

h3,
.h3 {
  font-size: $text-h3;
}

h4,
.h4 {
  font-size: $text-h4;
}

h5,
.h5 {
  font-size: $text-h5;
}

h6,
.h6 {
  font-size: $text-h6;
}

.bold {
  font-weight: 800;
}

p,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $baseFontFamily;
  margin-bottom: .1em;
  letter-spacing: .05em;

}

p,
a {
  font-size: $text-base;
}


a {
  cursor: pointer;
}


.green {
  color: $green-primary
}