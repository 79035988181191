@import '../../../styles/main.scss';


.container {
    display: flex;
    align-self: center;
    flex-direction: column;
    min-height: 50vh;
    width: 100%;
    margin-bottom: 3rem;

    .content_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .heading_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
                font-size: $text-h6;
            }
        }


        .button_wrapper {
            display: flex;
            flex-direction: column;
            position: relative;

            .arrow {
                position: absolute;
                height: 5rem;
                fill: $primary;
                left: -62%;
                top: 45%;
                transform: rotate(-100deg)
            }
        }

        .text {
            font-size: $text-xxs;
            line-height: 2;
            max-width: 750px;
        }

        img {
            height: 22.5vw;
            border-radius: 100%;
            align-self: center;
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: unset;
        min-height: unset;
        padding-bottom: 9.5rem;


        .content_container {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .heading_wrapper {
                h2 {
                    margin-bottom: 1rem;
                }
            }

            .text {
                font-size: $text-xs;
                line-height: 2;
                max-width: 300px;
                text-align: center;
            }

            .button_wrapper {
                align-items: center;

                .arrow {
                    height: 6.5rem;
                    left: -32%;
                    top: 115%;
                    transform: rotate(-120deg)
                }
            }

            img {
                height: 42.5vw;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        min-height: 50vh;
    }
}