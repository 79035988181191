@import '../../../styles/main.scss';

.container {
    display: flex;
    align-self: center;
    flex-direction: column;
    min-height: 50vh;
    width: 100%;
    padding-bottom: 2.5rem;

    .content_container {
        display: flex;
        flex-direction: row;
        gap: 3rem;

        .headline_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex: 2;
            gap: 1rem;

            .headline {
                transition: all 0.2s ease-out;
                border-bottom: .1rem solid rgba($dark, .5);
                cursor: pointer;

                h2 {
                    color: rgba($dark, .5);
                    font-size: $text-h6;
                    transition: all 0.2s ease-out;

                }

                p {
                    color: rgba($dark, .5);
                    font-size: $text-xxs;
                    margin-bottom: 1rem;
                    transition: all 0.2s ease-out;
                }

                &.active {
                    border-bottom: .1rem solid $primary;

                    h2,
                    p {
                        color: $dark;
                    }
                }
            }
        }

        .card_wrapper {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            width: 100%;
            flex: 3;
            gap: 1.5rem;
        }

        .card {
            display: flex;
            flex-direction: column;
            align-items: center;

            .inner {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 12.5svw;
                background-color: $primary-300;
                border-radius: .5rem;
                border: .125rem solid $dark;
                aspect-ratio: .75;

                .icon {
                    fill: $dark;
                    width: 80%;
                }
            }

            p {
                font-size: .5rem;
                margin-top: .25rem;
            }
        }

        .text {
            font-size: $text-xxs;
            margin-bottom: 1.5rem;
            line-height: 2;
        }

        img {
            height: 22.5vw;
            border-radius: 100%;
            align-self: center;
        }
    }

    @include media-breakpoint-down(md) {
        padding-bottom: 1rem;
        min-height: unset;

        .content_container {
            padding-top: 1.5rem;
        }

    }

    @include media-breakpoint-down(xs) {
        .content_container {
            padding-top: unset;
            flex-direction: column;

            .text {
                font-size: $text-xs;
                margin-bottom: 1.5rem;
                line-height: 2;
            }

            .section {
                align-items: center;
            }

            .card_wrapper {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                gap: 1rem;
                min-height: 37.5svh;
            }

            .card {
                overflow-x: hidden;

                .inner {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 26svw;
                    background-color: $primary-300;
                    border-radius: .5rem;
                    border: .15rem solid $dark;
                    aspect-ratio: .65;
                }

                p {
                    font-size: .55rem;
                    text-align: center;
                }
            }

        }
    }
}