@import '../../../styles/main.scss';

.container {
    display: flex;
    min-height: 100svh;
    background-color: $background;
    padding-top: var(--max-header-height);
    padding-left: 5rem;
    padding-right: 5rem;
    overflow-x: hidden;

    .wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 6.5rem;

        .wrapper {
            flex-direction: column;
            justify-content: unset;
            align-items: unset;
            margin: unset;
        }

    }



    @include media-breakpoint-down(xs) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}