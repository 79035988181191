@import './breakpoints';


// COLORS
$white: #ffffff;
$black: #000000;
$gray-50: #fafafa;
$gray-100: #f4f4f5;
$gray-200: #e4e4e7;
$gray-300: #d4d4d8;
$gray-400: #a1a1aa;
$gray-500: #71717a;
$gray-600: #52525b;
$gray-700: #404040;
$gray-800: #27272a;
$gray-900: #18181b;
$green-primary: #2BB673;
$blue-primary: #0BA6D9;
$purple-primary: #520DFC;
$red-primary: #FF0E00;
$orange-primary: #FC8E0F;
$yellow-primary: #D9CE0B;

// Custom Colors
$background: #F8F8F8;
$dark: #212121;
$primary: #2bb673;
$secondary: #b62b6e;

$linkColor: $secondary;
$linkColorHover: $background;
$errorColor: $red-primary;

// Primary Gradient --> https://www.colors.tools/color-theme/
$primary-50: #f4fbf8;
$primary-100: #cceddd;
$primary-200: #a3dfc2;
$primary-300: #7bd1a8;
$primary-400: #53c38d;
$primary-500: #2bb673;
$primary-600: #22915c;
$primary-700: #196d45;
$primary-800: #11482e;
$primary-900: #082416;

// TYPOGRAPHY
$text-xxs: .7rem;
$text-xs: 1rem;
$text-sm: 1.25rem;
$text-base: 1.5rem;
$text-h6: 1.75rem;
$text-h5: 2rem;
$text-h4: 2.25rem;
$text-h3: 2.5rem;
$text-h2: 2.75rem;
$text-h1: 3rem;

$font-thin: 100;
$font-normal: 400;
$font-bold: 700;

$baseFontFamily: "Mona Sans", "Mona Sans Header Fallback", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// DURATIONS
$durationSlower: 0.75s;
$durationSlow: 0.5s;
$durationNormal: 0.25s;
$durationFast: 0.2s;
$durationQuick: 0.1s;
$springIn: cubic-bezier(0.52, 0.01, 0.16, 1);

// Z-INDEX
$z-max: 1000;
$z-top: 900;
$z-middle: 500;
$z-bottom: 0;
$z-negative: -1;


// GRID
$grid-breakpoints: (
  xs: 500px,
  sm: 768px,
  md: 1024px,
  lg: 1366px,
  lgr: 1600px,
  xl: 1920px) !default;

:root {
  --max-header-height: 4.5rem;
  --min-header-height: 3.25rem;
  --max-header-logo-scale: 1;
  --min-header-logo-scale: .75;
  --primary: #F8F8F8;
  --dark: #212121;
  --green-primary: #2BB673;

  @include media-breakpoint-down(md) {
    --max-header-height: 7.5rem;
    --min-header-height: 5rem;
    --min-header-logo-scale: .8;
  }

  @include media-breakpoint-down(xs) {
    --max-header-height: 5rem;
    --min-header-height: 3.25rem;
    --min-header-logo-scale: .8;
  }
}