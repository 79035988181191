@import '../../../styles/main.scss';


.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    min-height: 10rem;
    width: 100%;
    background-color: $primary;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1.5rem;

        .social_container {
            display: flex;
            flex-direction: row;
            gap: .75rem;
        }

        .text_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 1.25rem;
            color: white;

            .logo {
                height: 1.5rem;
                width: 1.5rem;
                margin-bottom: 1rem;
            }

            h6 {
                font-size: $text-xxs;
                margin-bottom: .25rem;
                font-weight: $font-normal;
            }
        }
    }

    .bottom_container {
        display: flex;
        align-items: center;
        flex-direction: column;

        p {
            color: white;
            font-size: .5rem;
        }
    }

    @include media-breakpoint-down(md) {
        .inner {
            .text_container {
                .logo {
                    height: 2.75rem;
                    width: 2.75rem;
                    margin-bottom: 1.5rem;
                }
            }
        }

        .bottom_container {
            p {
                font-size: $text-xxs;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        min-height: 12.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;

        .inner {
            flex-direction: column;
            padding-bottom: 1.5rem;

            .text_container {
                align-items: center;
                padding-bottom: 2.5rem;

                h6 {
                    font-size: $text-xs;
                }
            }
        }
    }
}