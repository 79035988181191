@import '../../../styles/main.scss';

.container {
    position: fixed;
    display: flex;
    background-color: var(--header-bg-color);
    backdrop-filter: blur(14px);
    -webkit-backdrop-filter: blur(14px);
    height: var(--max-header-height);
    width: 100vw;
    z-index: $z-top;

    .inner {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 5rem;
        padding-right: 5rem;
        z-index: $z-top;
    }

    .logo {
        padding: unset;
        z-index: $z-top;
        cursor: pointer;

        img {
            transition: all 0.2s ease-out;
            height: 2.25rem;
            aspect-ratio: 1;
        }
    }

    @include media-breakpoint-down(md) {
        .logo {
            height: 2.9rem;

            img {
                height: 3rem;
            }
        }

    }


    @include media-breakpoint-down(xs) {
        .logo {
            img {
                height: 2.25rem;
            }
        }

        .inner {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}

.desktop_nav {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;

    // @include media-breakpoint-down(md) {
    //     display: none;
    // }

    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.mobile_nav {
    display: none;

    // @include media-breakpoint-down(md) {
    //     display: flex;
    // }

    @include media-breakpoint-down(xs) {
        display: flex;
    }
}

.burger {
    background: transparent;
    border: none;
    display: block;
    height: 1.42rem;
    padding: 5px;
    position: relative;
    aspect-ratio: 1.7;
    z-index: $z-top;

    span {
        background-color: $dark;
        display: inline-block;
        height: 2px;
        position: absolute;
        right: 50%;
        transform: translate(50%, -50%);
        transform-origin: center;
        width: 100%;
        border-radius: 4px;
    }

    .top,
    .bottom {
        transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    .top {
        top: 0;
        transform: rotate(0deg) translate(50%, -50%);
        z-index: 1;
    }

    .middle {
        opacity: 1;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        transition: opacity 0.3s ease-in-out;
        width: 70%;
    }

    .bottom {
        top: 100%;
        z-index: 0;
    }

    &.active {
        .top {
            top: 50%;
            transform: translate(50%, -50%) rotate(45deg);
        }

        .middle {
            opacity: 0;
        }

        .bottom {
            top: 50%;
            transform: translate(50%, -50%) rotate(-45deg);
        }

        .menu_wrap {
            transition: opacity .3sec ease-in-out, transform 0s ease-in-out 0s;
            transform: translate(0, 0);

            .menu {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

}


.menu {
    display: none;
    position: fixed;
    height: 0;
    right: 0;
    left: 0;
    top: 0;
    opacity: 1;
    transition: opacity 0.2s ease-out;
    z-index: $z-negative;
    background-color: $background;
    height: 100vh;

    .inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;

        .nav_links {
            display: flex;
            height: 60%;
            gap: 2.25rem;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }


        .social_links {
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20%;
            scale: 1.05;
            gap: 1rem;
            animation: fade-in .5s forwards;
            animation-delay: .5s;
        }
    }
}

.mobileNavLink {
    scale: 1.4;
    opacity: 0;
    transform: translateY(20px);
    animation: fade-in .5s forwards;
}


@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.mobileNavLink:nth-child(1) {
    animation-delay: 0.1s;
}

.mobileNavLink:nth-child(2) {
    animation-delay: 0.2s;
}

.mobileNavLink:nth-child(3) {
    animation-delay: 0.3s;
}

.mobileNavLink:nth-child(4) {
    animation-delay: 0.4s;
}

