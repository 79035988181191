@import '../../../styles/main.scss';

.container {
    display: flex;
    min-height: 100svh;
    background-color: $primary;
    background: url(../../../assets/images/shawn-kemp.gif), rgba($color: #000000, $alpha: .6);
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: multiply;
    background-size: cover;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;

    h2 {
        margin-bottom: 2rem;
    }

    p {
        font-size: $text-xs;
        cursor: pointer;
    }
}