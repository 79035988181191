@import '../../../styles/main.scss';

.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 70svh;

    .text_container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .button_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 1.5rem;
            gap: 2rem;
        }

        h1 {
            margin-bottom: .75rem;
        }

        p {
            max-width: 600px;
        }
    }

    .modelContainer {
        position: relative;
        width: clamp(20px, 30vw, 600px);
        aspect-ratio: .8;
    }

    @include media-breakpoint-down(md) {
        min-height: 35vh;

        .text_container {
            .button_container {
                gap: 3rem;
                padding-top: 2.5rem;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        flex-direction: column;

        .text_container {
            .button_container {
                justify-content: space-evenly;
                gap: 1rem;
            }
        }

        .modelContainer {
            height: 40svh;
            max-height: 450px;
            width: 80%;
            margin: auto;
            margin-top: 3rem;
        }
    }
}